
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class=" py-2 mb-4">Shop Items</h1>
    <v-select
      v-model="selectedColor"
      :value="item_color_title"
      item-text="item_color_title"
      :items="colors"
      label="Color"
      outlined
      class="search-field"
    ></v-select>

    <v-select
      v-model="selectedSize"
      :items="sizes"
      label="Size"
      outlined
      class="search-field"
    ></v-select>

    <v-select
      v-model="selectedType"
      :items="types"
      label="Type"
      outlined
      class="search-field"
    ></v-select>
	
			<v-layout row wrap mt-5>
                <!-- {{rows}} -->
                <!-- show the shop_items in cards with them imags on top  -->
                 <v-text-field v-model="search" label="Search" class="search-field" outlined></v-text-field>

    <div class="view-cards">
      <div v-for="(item, index) in filteredItems" :key="index" class="view-card">
        <div class="card-content">
          <div class="card-header">
            <v-img max-width="500" :src="$imageURL + 'items/image/' + item.file_attachment_path" />  
          </div>
          <div class="card-body">
            <p>Barcode: {{item.shop_item_id}}</p>
            <p>QTY: {{item.shop_item_quantity}}</p>
            <p>Note: {{item.shop_item_note}}</p>
            <p>{{item.shop_item_link}}</p>
            <p>Price: {{item.shop_item_price}}</p>
            <!-- <p>{{item.shop_item_status}}</p> -->
            <!-- <p>{{item.shop_item_date}}</p> -->
            <p>Type: {{item.item_type_title}}</p>
            <p>Color: {{item.item_color_title}}</p>
            <p>Size: {{item.item_size_title}}</p>
          </div>
        </div>
      </div>
    </div>
            </v-layout>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search" dense outlined hide-details></v-text-field>

				
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.shop_items.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteShopItems(selected_shop_items.shop_item_id)">
						{{$store.getters.language.data.shop_items.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="image_dialog" persistent>
			<v-card>
				<v-card-title>
					Images
				</v-card-title>
				<v-card-text>
					<v-layout row wrap>
						<!-- <v-flex xs12 v-for="image in images">
                            <v-img :src="image.shop_item_image_link" :alt="image.shop_item_image_link" :key="image.shop_item_image_id" width="100%" height="100%"></v-img>
                        </v-flex> -->
						<v-flex xs12 lg2 xl2 md12 sm12 v-for="item,index in fileRows" :key="index">

							<div class="mx-3">
								<v-img max-width="500" v-bind:src="$imageURL + 'items/image/' + item.file_attachment_path" />
							</div>
							<!-- {{item.file_attachment_path}} -->

						</v-flex>
					</v-layout>
					
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="image_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	

		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/shop_items.request.js'
	import filerequests from './../../requests/file_attachments.request.js'



	export default {
		data() {
			return {
				shop_items: {
					shop_item_link: '',
				},
				file_attachments: {},
				shop_item_histories: {},
				shop_item_moves: {},
				shop_item_moves_add: {
				},
				img_file: null,
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
                selectedColor: '',
      selectedSize: '',
      selectedType: '',
      shopItemId: '',
				rows: [],
				fileRows: [],
				selected_shop_items: {},
				delete_dialog: false,
				image_dialog: false,
				history_dialog: false,
				move_dialog: false,
				headers: [

					{
						text: this.$store.getters.language.data.shop_items.shop_item_barcode,
						align: 'start',
						sortable: true,
						value: 'shop_item_id',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_quantity,
						align: 'start',
						sortable: true,
						value: 'shop_item_quantity',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_note,
						align: 'start',
						sortable: true,
						value: 'shop_item_note',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_link,
						align: 'start',
						sortable: true,
						value: 'shop_item_link',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_price,
						align: 'start',
						sortable: true,
						value: 'shop_item_price',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_fav,
						align: 'start',
						sortable: true,
						value: 'shop_item_fav',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_status,
						align: 'start',
						sortable: true,
						value: 'shop_item_status',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_date,
						align: 'start',
						sortable: true,
						value: 'shop_item_date',
					},
					{
						text: this.$store.getters.language.data.item_types.item_type_title,
						align: 'start',
						sortable: true,
						value: 'item_type_title',
					},
					{
						text: this.$store.getters.language.data.item_colors.item_color_title,
						align: 'start',
						sortable: true,
						value: 'item_color_title',
					},
					{
						text: this.$store.getters.language.data.item_sizes.item_size_title,
						align: 'start',
						sortable: true,
						value: 'item_size_title',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'shop_item_barcode',
					},
                    {
                        text: this.$store.getters.language.data.file_attachments.file_attachment_path,
                        align: 'start',
                        sortable: true,
                        value: 'file_attachment_path',
                    }
				],
			}
		},
		computed: {
            types() {
				return this.$store.getters.item_types_list
			},
			colors() {
				return this.$store.getters.item_colors_list
			},
			sizes() {
				return this.$store.getters.item_sizes_list
			},            
 filteredItems() {
      // Filter items based on the search input
      if (this.search) {
        const searchValue = this.search.toLowerCase();
        return this.rows.filter(item =>
          Object.values(item).some(value =>
            String(value).toLowerCase().includes(searchValue)
          )
        );
      } else {
        return this.rows;
      }
    }



		},
		mounted() {
			this.readShopItems();
		},
		methods: {
			readShopItems() {
				this.loading = true
				requests.getAllShopItemsView().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readFileAttachments(i) {
				this.loading = true
				var item_id = i.shop_item_id
				filerequests.getFileAttachmentsByColumn('shop_item_id', item_id).then(r => {
					if (r.status == 200) {
						this.fileRows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
	
		
			

		
			openDialog(i) {
				this.selected_shop_items = i
				
				this.readFileAttachments(i)
				this.image_dialog = true
			},

		},
	}
</script>
<style scoped>
.view-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.view-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 15%; /* Adjust width based on your design */
  /* Add any other styling to customize the view cards */
}

.card-content {
  /* Additional styling for card content */
}

.card-header {
  /* Additional styling for header section */
}

.card-body {
  padding: 10px;
  /* Additional styling for card body */
}
</style>